import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
// import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/mmplay.png";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LoginModal from "../views/components/authModals/LoginModal";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import ForgotPasswords from "../views/components/authModals/ForgotPassword";
import ResetPassword from "../views/components/authModals/ResetPassword";
import ModalWrapper from "./ModalWrapper";
const Header = ({ agentCode = "" }) => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultAuthModal, setDefaultAuthModal] = useState(
    APP_CONST.AUTH_MODAL.LOGIN
  );

  const handleShow1 = () => setShow1(!show1);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);

  useEffect(() => {
    if (agentCode) {
      setDefaultAuthModal(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
      setShowAuthModals(true);
    }
  }, [agentCode]);

  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultAuthModal(modalName);
  };

  return (
    <header className="header header_bfrlgn">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <Button
                variant="primary menu-btn"
                onClick={handleShow1}
                className="d-block d-md-none"
              >
                <img src={MenuIcon} alt="User Menu Icon" width={25} />
              </Button>
              <div className="logo">
                <a href="/">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </a>
              </div>
              <div className="header-left ms-4 d-none d-lg-block">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="d-none"
                />
                <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                  <Nav className="me-auto">
                    {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                    <Nav.Link href="/sportsbook/Cricket"> Sportsbook</Nav.Link>
                    <Nav.Link href="/live-dealer">Live Casino</Nav.Link>
                    <Nav.Link href="/casino/supernowa">Supernowa</Nav.Link>
                    <Nav.Link href="/casino/spribe/aviator">Aviator</Nav.Link>
                    <Nav.Link href="/casino/qtech">Qtech</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>
              <div className="headerRight ms-auto">
                <ul className="d-none">
                  <li>
                    <a
                      className="tg"
                      href={
                        appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={TelegramIcon} alt="Telegram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="wtp"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon} alt="Whatsapp Icon" />
                    </a>
                  </li>
                  <li className="theme-btn">
                    <button type="button" className="btn" onClick={toggleClass}>
                      <img src={MoonLight} alt="moon" className="dark_theme" />
                      <img src={SunLight} alt="sun" className="light_theme" />
                    </button>
                  </li>
                </ul>

                <Button
                  variant="primary"
                  onClick={() => {
                    isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  className="login_btn me-2"
                >
                  Log In
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
                      : navigate("/mobile-number");
                  }}
                  className="signup_btn"
                >
                  Sign Up
                </Button>
                <ul className="" style={{ marginLeft: "5px" }}>
                  <li>
                    <GamesSearchModal />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultAuthModal}
          handleClose={setShowAuthModals}
          agentCode={agentCode}
        />
      )}
      {show1 && <MobLeftbar className="d-block d-md-none" />}
    </header>
  );
};

export default Header;
